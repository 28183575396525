<template>
  <team-member-profile
    name="Alahna Cullen"
    qualifications="BSc (Hons)"
    charter="MCSP"
    registration="HCPC Registered: PH78551"
    :pic="require(`@/assets/images/alahna.jpg`)"
  >
    <p>
      Alahna graduated as a Physiotherapist from Southampton University in 2006
      and began her carer in the University Hospital of Southampton. She
      completed a number of core rotations including musculoskeletal,
      respiratory, neurological rehabilitation and elderly care.
    </p>
    <p>
      Following the completion of her core rotations, Alahna went on to
      specialise in the rehabilitation of individuals suffering a neurological
      diagnosis including Stroke, MS, Head Injuries and Spinal Injuries to name
      a few.
    </p>
    <p>
      This included time out in the community, in a gym setting as well as on
      inpatient rehabilitation wards. In 2013 Alahna became the Therapy Team
      Leader and Clinical Specialist on a Neurological Rehabilitation Ward which
      she thoroughly enjoyed and gained valuable experience in the management of
      long-term neurological conditions and their symptoms including muscle
      weakness, muscle spasticity and the use of orthotics in function.
    </p>
    <p>
      Following the birth of her two children, Alahna is excited to join the
      team of Specialist Neurological Physiotherapist at Balance Neuro
      Physiotherapy and to focus on providing rehabilitation to people in their
      own home more locally to her hometown of Ringwood.
    </p>
    <p>
      She maintains some clinical work and supervisory work at Shelley Park
      Neuro Care Center and also enjoys occasional University lecturing to
      maintain and share her clinical skills.
    </p>
    <p>
      Alahna has a busy home life looking after her 2 children and black
      Labrador, and also enjoys playing Netball for a local team.
    </p>
    <p>
      She is passionate about her role as a Physiotherapist and enjoys enabling
      people to maximise their full potential in life.
    </p>
  </team-member-profile>
</template>

<script>
import TeamMemberProfile from "../../layouts/TeamMemberProfile.vue";
export default {
  name: "Alahna",
  components: {
    "team-member-profile": TeamMemberProfile,
  },
};
</script>
